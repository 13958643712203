import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Dapp from '@/dapp-lib-js/index'
import networks from '@/dapp-lib-js/networks'
// import ElementUI from 'element-ui'
import VueRouter from 'vue-router'
import routes from './router/router'
import storage from '@/plugins/store/storage'
import './plugins/element.js'
import BigNumber from 'bignumber.js'
import VueMatomo from 'vue-matomo'

BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN })

// Vue.use(ElementUI)
Vue.use(VueRouter)
Vue.use(Vuex)


window.networkId = 'eth'
window.contractAddr = '0xc9ba829b2c0d7d7446b2eaf81df2f7b5a975e210';
window.dapp = new Dapp({extension: 'MetaMask'})

const WALLET_CONNECT_STATUS_CHANGED = 'WALLET_CONNECT_STATUS_CHANGED'
const state = {
  walletConnectStatus: {
    address: '',
    isTargetNetwork: false,
    connected: false,
  },
}
const actions = {
  // eslint-disable-next-line no-shadow
  [WALLET_CONNECT_STATUS_CHANGED]: ({state}) => {
    let currentChainId = window.dapp.network.chainId
    let findRet = networks.find((network) => network.chainId === currentChainId)
    storage.setItem('last_connected_network_id', findRet ? findRet.id : '')

    let address = window.dapp.currentAccount.address
    let isTargetNetwork = findRet ? findRet.id === window.networkId : false
    let connected = !!address
    if (connected) {
      if (state.walletConnectStatus.address !== address ||
        state.walletConnectStatus.isTargetNetwork !== isTargetNetwork ||
        state.walletConnectStatus.connected !== connected) {
        state.walletConnectStatus = {address, isTargetNetwork, connected}
      }
    } else {
      state.walletConnectStatus = {address: '', isTargetNetwork: false, connected}
    }
  },
}

const store = new Vuex.Store({
  modules: {
    WalletConnect: {
      state,
      actions,
    },
  },
})
Vue.$store = store
Vue.prototype.$store = store

window.addEventListener('load', async () => {
  // Modern dapp browsers...
  let dapp = window.dapp

  dapp.onEnabled(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  dapp.onNetworkChanged(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  dapp.onAccountChanged(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  dapp.onDisconnect(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  let inStoreNetworkId = storage.getItem('last_connected_network_id')
  if (window.networkId === inStoreNetworkId) {
    await dapp.enableBrowserExtension(inStoreNetworkId)
  }
})

const router = new VueRouter({
  routes
})

Vue.use(VueMatomo, {
  host: 'https://matomo.longzu.co',
  siteId: 1,
  router: router,
})

const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
router.beforeEach(async (to, from, next) => {
  if (isMobile && to.meta.mobileWeb) {
    next({ name: to.meta.mobileWeb })
  } else if (!isMobile && to.meta.pcWeb) {
    next({ name: to.meta.pcWeb })
  } else {
    next()
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
